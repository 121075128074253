import { createContext } from "react";
import { AuthContextProp } from "./types";








export const AuthContext = createContext<AuthContextProp>({
  user: undefined,
  storedEmail: undefined,
  isPremium: false,
  setClientAuth: () => {throw new Error("this component is not inside AuthContext")},
  clearClientAuth: () => {throw new Error("this component is not inside AuthContext")},
  setStoredEmail: () => {throw new Error("this component is not inside AuthContext")},
  clearStoredEmail: () => {throw new Error("this component is not inside AuthContext")},
  setIsPremium: () => {throw new Error("this component is not inside AuthContext")}
})