import axios from "axios";

const requestor = axios.create({
  baseURL: import.meta.env.VITE_API_HOST + "/api/v2"
});

let axiosInterceptor: number;

/** 
 * set axios bearer token  
 * */
export function setRequestToken(token : string){
  if(!token) throw new Error("token is required");
  axiosInterceptor = requestor.interceptors.request.use(config => {
    const newConfig = {...config}
    newConfig.headers["Authorization"] = `Bearer ${token}`;
    // i don't know, but apparently i must run console.log to make sure this auth is set.
    // console.log(`Bearer ${token}`);
    return newConfig
  })
}

/**
 * clear token,, solution from bug above
 * use it when your user logout
 */
export function clearReuestToken(){
  requestor.interceptors.request.clear();
  // requestor.interceptors.request.eject(axiosInterceptor);
}

export {requestor}