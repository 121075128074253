import { ContentCarouselData } from "@components/slider/smooth-carousel/ContentCarousel";
import image1 from "./assets/image1.png";
import image2 from "./assets/image2.png";
import image3 from "./assets/image3.png";
import image4 from "./assets/image4.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { SmoothCarousel } from "@components/slider/smooth-carousel/SmoothCarousel";
import { cn } from "@utils/tailwind-merge";

const slidesEN = [
  { 
    url: image1, 
    title: "Protect Your Business from Ransomware", 
    description : `
    At FixRansomware.com, we offer comprehensive ransomware protection services
    such as system audits, ransomware attack insurance, implementation of secure cloud backup solutions, 
    and provide team awareness training on cybersecurity best practices to ensure 
    your data and systems are protected.
    ` 
  },
  { 
    url: image2, 
    title: "24/7 Emergency Response Service", 
    description : `
    In the event of a ransomware attack, time is of the essence.
    That's why we offer a 24/7 emergency response service to help you get back up and running as quickly as possible. 
    With the collaboration of dozens of experts from various countries, we can identify the source of the attack, 
    limit the damage, and quickly restore your data and systems!
    `
  },
  { 
    url: image3, 
    title: "Affordable and Flexible Solutions", 
    description : `
    We understand that every business is unique, and that's why we offer affordable solutions to meet your specific needs. 
    Whether you are a small startup or a large corporation, we can tailor our services to your budget and requirements. 
    Contact us today to learn more about our pricing and service options.
    `
  },
  { 
    url: image4, 
    title: "Trusted by Businesses Worldwide", 
    description : `
    We have a proven track record, and a team of experts with over 5 years of experience. 
    This is why we are trusted by state-owned companies such as Kenya Airport Authority, 
    PLN Engineering, Daido Manufacturing, GIZ-DE Germany, and many more.
    `
  },
] as const satisfies Readonly<ContentCarouselData>[];

const slidesID = [
  { 
    url: image1, 
    title: "Lindungi Bisnis Anda dari Ransomware", 
    description : `
    Di Fixransomware.com, kami menawarkan layanan perlindungan ransomware yang komprehensif 
    seperti audit sistem, asuransi serangan ransomware, penerapan solusi pencadangan cloud 
    yang aman, dan memberikan pelatihan kesadaran tim tentang praktik terbaik keamanan siber 
    untuk memastikan data dan sistem Anda terlindungi.
    ` 
  },
  { 
    url: image2, 
    title: "Layanan Tanggap Darurat 24/7", 
    description : `
    Jika terjadi serangan ransomware, waktu adalah hal yang sangat penting. 
    Itu sebabnya kami menawarkan layanan tanggap darurat 24/7 untuk membantu Anda kembali 
    aktif dan berjalan secepat mungkin. Dengan kolaborasi puluhan ahli dari berbagai negara, 
    kami dapat mengidentifikasi sumber serangan, membatasi kerusakan, dan memulihkan data 
    dan sistem Anda dengan cepat!
    `
  },
  { 
    url: image3, 
    title: "Solusi Terjangkau dan Fleksibel", 
    description : `
    Kami memahami bahwa setiap bisnis itu unik, dan itulah sebabnya kami menawarkan solusi 
    terjangkau untuk memenuhi kebutuhan spesifik Anda. Baik Anda perusahaan rintisan kecil 
    atau perusahaan besar, kami dapat menyesuaikan layanan kami sesuai anggaran dan kebutuhan Anda. 
    Hubungi kami hari ini untuk mempelajari lebih lanjut tentang harga dan opsi layanan kami.
    `
  },
  { 
    url: image4, 
    title: "Dipercaya oleh Bisnis di Seluruh Dunia", 
    description : `
    Kami memiliki rekam jejak yang terbukti, dan tim ahli dengan pengalaman lebih dari 5 tahun. 
    Inilah sebabnya kami dipercaya oleh perusahaan milik negara seperti Otoritas Bandara Kenya, 
    PLN Engineering, Daido Manufacturing, GIZ-DE Jerman, dan masih banyak lagi.
    `
  },
] as const satisfies Readonly<ContentCarouselData>[];

interface CompanyPhotoCarouselProps {
  className?: string;
}

interface CompanyPhotoCarouselProps {
  className?: string;
}
export function CompanyPhotoCarousel(props: CompanyPhotoCarouselProps){
  const { i18n } = useTranslation();

  return (
    <div className={cn(
      "h-full flex flex-col justify-center p-10 2xl:p-15 overflow-hidden",
      props.className
      )}>

      <SmoothCarousel data={i18n.resolvedLanguage === "id" ? slidesID : slidesEN} />
      {/* <SliderImage slides={slidesEn} />  */}
      {/* <Carousel 
        autoPlay={true} 
        loop={true}
      >
        {slides.map((item, i) => (
          <img 
            src={item.url as string}
            key={i}
            width="100%"
            draggable="false"
          />
        ))}
      </Carousel> */}
    </div>
  );
}