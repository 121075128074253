import { ReactNode, useState } from "react";
import { VirusContext } from "./context";






interface VirusProviderProps {
  children: ReactNode;
}


export function VirusProvider(props: VirusProviderProps){

  const [selectedVirus, setSelectedVirus] = useState<string | undefined>(undefined)


  return (
    <VirusContext.Provider 
      value={{
        selectedVirus,
        clearSelectedVirus: () =>{ setSelectedVirus(undefined)},
        setSelectedVirus: (virus: string) => {setSelectedVirus(virus)}
      }}
    >
      {props.children}
    </VirusContext.Provider>
  )

}