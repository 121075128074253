import { OutlineButton } from "@components/form";
import { AuthContext } from "@entities/auth/context";
import { clearReuestToken } from "@services/admin.fixransomware.com";
import { removeLocalToken } from "@services/local-storage";
import { useContext } from "react";









export function LogoutButton() {
  const authContext = useContext(AuthContext);


  return(
    <OutlineButton
      variant="danger"
    >
      Logout
    </OutlineButton>
    
  )

}

interface LogoutWrapperProps {
  children: React.ReactNode;
  onLogout: () => void;
}
export function LogoutWrapper(props: LogoutWrapperProps) {

  const logout = useLogout({
    onSuccess: props.onLogout
  })

  return (
    <button onClick={logout} className="w-full">
      {props.children}
    </button>
  );
}


interface useLogoutProps {
  onSuccess: ()=> void;
}
export function useLogout(props: useLogoutProps) {
  
  return ()=> {
    removeLocalToken();
    clearReuestToken();

    return props.onSuccess();
  }

}