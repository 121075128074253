import { ReactElement } from "react"



type MainLayoutProps = {
  navbarSlot: ReactElement;
  contentSlot: ReactElement;
  footerSlot: ReactElement;
  frameSlot?: ReactElement | null;
}



export function MainLayout(props: MainLayoutProps){
  return (
    <div className="block md:flex flex-col bg-secondary-dark h-screen min-h-screen md:max-h-screen">
      {props.frameSlot}
      <div className="bg-secondary-base rounded-t-md md:max-h-full overflow-auto md:overflow-hidden min-h-full block md:flex flex-col">
        <nav>{props.navbarSlot}</nav>
        <main className="text-white min-h-screen overflow-x-hidden ">
          {props.contentSlot}
        </main>
        <footer>{props.footerSlot}</footer>
      </div>
    </div>
  )
}