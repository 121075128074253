import { ForwardedRef, ReactNode, useEffect, useImperativeHandle, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { toast } from "react-toastify";
import { Currency } from "@entities/common/currency";
import { useSendAnonymousDonation } from "./use-send-anonymous-donation";
import { SendAnonymousDonationModal } from "./send-anonymous-donation-modal";
import { SelectAnonymousDonationFile } from "./select-anonymous-donation-file";

export interface SendAnonymousDonationButtonAccess {
  setOpen: (val: boolean) => void;
}


export type TranferDataType = {
  paymentMethod : "BNI" | "QRIS";
  currency: Currency;
  nominal : string;
  email: string;
} 

interface SendAnonymousDonationButtonProps {
  children: ReactNode;
  ref?: ForwardedRef<SendAnonymousDonationButtonAccess>
  onSuccess?: () => void;
}

/**
 * Component for rendering a button that facilitates the sending of anonymous donations.
 * It manages the state for the donation modal, file selection modal, and transfer data.
 * 
 * Props:
 * - `children`: ReactNode elements to be displayed within the button.
 * - `ref`: Optional ref to access the `setOpen` method for controlling the modal state.
 * 
 * State:
 * - `isModalOpen`: Boolean indicating if the donation modal is open.
 * - `selectedProofFile`: Array of files selected as proof of donation.
 * - `isSelectModalOpen`: Boolean indicating if the file selection modal is open.
 * - `transferData`: Object containing the payment method, nominal, currency, and email.
 * - `isLoading`: Boolean indicating if the donation is being processed.
 * 
 * Dependencies:
 * - `useSendAnonymousDonation`: Hook providing the `send` function to handle donation submission.
 * 
 * Side Effects:
 * - A `useEffect` hook logs the selected proof file to the console whenever it changes.
 * 
 * The component triggers toast notifications for success and error states, and manages modal visibility.
 */

export function SendAnonymousDonationButton({children,ref} : SendAnonymousDonationButtonProps){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProofFile, setSelectedProofFile] = useState<FileWithPath[]>([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false)
  const [transferData, setTransferData] = useState<TranferDataType>({
    paymentMethod: "BNI",
    nominal: "0",
    currency: Currency.IDR,
    email: "",
  });

  const {send} = useSendAnonymousDonation();
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: (val: boolean) => setIsModalOpen(val)
  }));

  // disini tempat API dengan server
  async function handleSendProof(){
    try {

      if(!selectedProofFile[0]) {
        toast.error("Please provide the payment proof");
        return;
      }

      setIsLoading(true);
      await send({
        paymentMethod: transferData.paymentMethod,
        nominal: parseInt(transferData.nominal),
        proof: selectedProofFile[0],
        currency: transferData.currency,
        email: transferData.email
      })
      
      toast.success("Sukses mengirim donasi, silahkan tunggu konfirmasi dari admin");
      setIsModalOpen(false);
      setIsSelectModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error((error as any)?.message ?? "Ada masalah dengan server");
    }
  }

  // MAGIC: this useEffect will prevent bug selectedProofFile empty.
  useEffect(() => {
    console.log("selected proof file...");
    console.log(selectedProofFile);
  }, [selectedProofFile])

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        {children}
      </div>
      <SendAnonymousDonationModal
        getFileModalOpen={() => setIsSelectModalOpen(true)}
        setTransferData={setTransferData}
        isOpen={isModalOpen}
        modalAccess={setIsModalOpen}
      />
      <SelectAnonymousDonationFile 
        filesValue={selectedProofFile}
        setFilesValue={setSelectedProofFile}
        isOpen={isSelectModalOpen}
        modalAccess={setIsSelectModalOpen}

        // # when click upload file in modal
        getSendFileModalOpen={() => handleSendProof()}
      />
    </>
  )
}