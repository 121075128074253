import { SolidButton } from "@components/form/button/SolidButton";
import { GoToLogin } from "@features/auth/got-to-login";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DonationBenefitModal } from "./donation-benefit";
import { SendAnonymousDonationButton, SendAnonymousDonationButtonAccess } from "@features/donation/send-anonymous-donation";



export default function AnonymousDonationPage(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();

  const aRef = useRef<SendAnonymousDonationButtonAccess>(null);

  return (
    <>
      <div className="mt-20">
        {/* title and description */}
        <div className="mb-10">
          <h1 className="text-2xl mb-2 font-bold">
            {t("pages.donation.main_title")}
          </h1>
          <p className="mb-2">
            {t("pages.donation.p_section_1")}
          </p>
          <p className="mb-2">
            {t("pages.donation.p_section_2")}
          </p>
        </div>

        <GoToLogin className="mb-5" />

        <div className="flex flex-col md:flex-row justify-between gap-2">
          {/* donate button, it will show upload file input */}
          <SendAnonymousDonationButton ref={aRef} >
            <SolidButton variant="warning" className="w-full">
              {t("pages.donation.donate_button")}
            </SolidButton>
          </SendAnonymousDonationButton>


          {/* show donation benefit */}
          <SolidButton
              variant="success"
              onClick={()=> setIsModalOpen(true)}
            >
            {t("pages.donation.open_modal_button")}
          </SolidButton>
        </div>
      </div>
      <DonationBenefitModal 
        isOpen={isModalOpen} 
        modalAccess={setIsModalOpen} 
        onDonateNowClick={() => {
          // clone benefit modal
          setIsModalOpen(false);

          // open donation upload file
          aRef.current?.setOpen(true);
        }}
      />
    </>
  )
}