import { AuthState, useInitializeSession } from "@features/auth/initialize-session/use-initialize-session";
import { ScreenLayout } from "@layouts/screen-layout";
import { Authorizing } from "../../widget/loader/authorizing";
import { Navigate, Outlet } from "react-router-dom";
import { MainLayout } from "@layouts/main-layout";
import { MainNavbar } from "../../widget/main-navbar";
import { Suspense } from "react";
import { Mounting } from "../../widget/loader/mounting";
import { MainFooter } from "../../widget/main-footer";
import { MountingMain } from "../../widget/loader/mounting-main";

export default function MainLayoutRoute(){
  // const o = useOutlet();

  const {authStatus} = useInitializeSession();

  if(authStatus === AuthState.LOADING) return (
    <ScreenLayout>
      <Authorizing />
    </ScreenLayout> 
  )

  if(authStatus === AuthState.UNAUTHORIZE) return (<Navigate to="/login"/>)

  return(
    <MainLayout
      navbarSlot={<MainNavbar />}
      contentSlot={
        <Suspense fallback={<MountingMain />}>
          <Outlet />
        </Suspense>
      }
      footerSlot={<MainFooter />}
    />
  )
}