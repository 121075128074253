import { AuthState, useInitializeSession } from "@features/auth/initialize-session/use-initialize-session";
import { ScreenLayout } from "@layouts/screen-layout";
import { Authorizing } from "../../widget/loader/authorizing";
import { Navigate, Outlet } from "react-router-dom";
import { AuthLayout } from "@layouts/auth-layout";
import { LogoAndLanguage } from "../../widget/logo-and-language";
import { CompanyPhotoCarousel } from "../../widget/company-photo-carousel";
import { Suspense } from "react";
import { Mounting } from "../../widget/loader/mounting";

export default function AuthLayoutRoute(){

  const {authStatus} = useInitializeSession();

  if(authStatus === AuthState.LOADING) return (
    <ScreenLayout>
      <Authorizing />
    </ScreenLayout> 
  )

  if(authStatus === AuthState.LOGGED_IN) return (<Navigate to="/"/>)

  return(
    <AuthLayout
      leftSlot={(
        // <Mounting className=" rounded" />
        <Suspense fallback={<Mounting className="rounded" />}>
          <Outlet />
        </Suspense>
      )}
      navbarSLot={<LogoAndLanguage />}
      rightSlot={<CompanyPhotoCarousel />}
    />
  )
}