// import { captureException } from "@sentry/react";
import { isAxiosError } from "axios";

export function extractAxiosRequestError(axiosError: any): Error{

  if(!(axiosError instanceof Error)){
    console.log(axiosError)
    // captureException(axiosError);
    return new Error("invalid error value");
  }
  if(!isAxiosError(axiosError)) return axiosError;
  const message = (axiosError.response?.data as any)?.message as string;

  if(message) return new Error(message);

  return axiosError;
}