




export const ClientType = {
  PREMIUM: "premium",
  BASIC: "basic"
}
export const ClientTypeValue = [ClientType.BASIC, ClientType.PREMIUM] as const;
export type ClientTypeValueType = 'premium' | 'basic'