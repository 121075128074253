import { DialogWithHeader } from "@components/dialog";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";



interface DonationBenefitModalProps {
  isOpen: boolean;
  modalAccess: Dispatch<SetStateAction<boolean>>
  onDonateNowClick?: () => any;
}

export function DonationBenefitModal(props: DonationBenefitModalProps){
  const {t} = useTranslation();

  function hadleClickOnDonateNow(){
    if(props.onDonateNowClick) props.onDonateNowClick();
  }

  return (
    <DialogWithHeader
      isOpen={props.isOpen}
      modalAccess={props.modalAccess}
      title="Offer"
      className="md:basis-[64rem]  shrink-0 p-3 "
    >
      <div className="min-w-[800px]">
        <div className="flex gap-5">
          <div className="basis-1/3   ">
            <h1 className="text-xl font-semibold">
              {t("pages.donation.modal.header")}
            </h1>
            <ul className="flex flex-col mt-9">
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section1")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section2")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section3")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section4")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section5")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section6")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section7")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section8")}
              </li>
              <li className="py-1.5 border-b border-secondary-light">
                {t("pages.donation.modal.section9")}
              </li>
            </ul>
          </div>
          <div className="basis-1/3 rounded bg-secondary-dark p-5">
            <h1 className="text-base font-semibold bg-secondary-base rounded p-4 py-6 text-center">
              {t("pages.donation.modal.header_free")}
            </h1>
            <ul className="flex flex-col">
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section1_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section2_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section3_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section4_free")}
              </li>
              <li className="py-4 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section5_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section6_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section7_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section8_free")}
              </li>
              <li className="py-1.5 border-b border-y-secondary-light text-center">
                {t("pages.donation.modal.section9_free")}
              </li>
            </ul>
            <button className="p-2 bg-secondary-light rounded mx-auto mt-2 block">
              Applied
            </button>
          </div>
          <div className="basis-1/3 rounded bg-amber-800 p-5">
            <h1 className="text-base font-semibold bg-amber-600 rounded p-4 py-6 text-center text-nowrap">
              {t("pages.donation.modal.header_premium")}
            </h1>
            <ul className="flex flex-col">
            <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section1_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section2_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section3_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section4_premium")}
              </li>
              <li className="py-4 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section5_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section6_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section7_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section8_premium")}
              </li>
              <li className="py-1.5 border-b border-amber-700 text-center">
                {t("pages.donation.modal.section9_premium")}
              </li>
            </ul>
            <button onClick={hadleClickOnDonateNow} className="p-2 bg-amber-500 rounded mx-auto mt-2 block">
              {t("pages.donation.modal.donate_button")}
            </button>
          </div>
        </div>
          <button onClick={()=>props.modalAccess(false)} className="rounded border border-success-dark hover:bg-success p-2 px-10 transition active:bg-success-dark block mx-auto mt-4">
          {t("pages.donation.modal.close_button")}
        </button>
      </div>
    </DialogWithHeader>
  )
}