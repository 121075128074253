
import { ComponentPropsWithoutRef } from "react";
import { FileWithPath } from "react-dropzone";
import { DialogWithHeader } from "@components/dialog";
import { DefaultDropzone } from "@components/form";
import { MAX_DONATION_FILE_PROOF_COUNT, MAX_DONATION_FILE_PROOF_SIZE } from "../donation.config";
import { SolidButton } from "@components/form/button/SolidButton";


interface SelectAnonymousDonationFileProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title">{
  filesValue: FileWithPath[];
  setFilesValue: React.Dispatch<React.SetStateAction<FileWithPath[]>>;
  getSendFileModalOpen: ()=> any;
}


export function SelectAnonymousDonationFile({
  filesValue, 
  setFilesValue, 
  isOpen, 
  getSendFileModalOpen, 
  modalAccess, /* ...props */
}: SelectAnonymousDonationFileProps){

  function handleSelectFile (){
    
    // modalAccess(false);
    getSendFileModalOpen();
  }

  return (
    <DialogWithHeader
      isOpen={isOpen}
      modalAccess={modalAccess}
      title="Upload Sample File"
      className="bg-secondary-base basis-1/3 max-h-[80%] overflow-auto"
    >
      <div className="p-2">
        <DefaultDropzone
          filesValue={filesValue}
          setFilesValue={setFilesValue}
          whenDragText={"Jatuhkan File anda di bawah"}
          whenIdleText={
            "klik atau drag file anda disini, ukuran maksimal" 
            + " file adalah 10 mb dengan jumlah 1 buah"
          }
          maxCount={MAX_DONATION_FILE_PROOF_COUNT} // hanya 1
          maxSize={MAX_DONATION_FILE_PROOF_SIZE} // sekitar 10 mb
        />
        <SolidButton 
          onClick={handleSelectFile} 
          variant="success"
          className="mt-3 block ms-auto"
        >
          Upload
        </SolidButton>
      </div>
    </DialogWithHeader>
  )
}