import { Client } from "@entities/client";
import { requestor } from "./requestor";
import { FRSResponse } from "./response";



interface SignUpPayload {
  fullname: string,
  email: string,
  phoneNumber: string,
  institution: string,
  address: string,
  password: string,
  extra: {
    institutionType: string,
    phoneCountryCode: string,
    language : string
  },
  addressDetail: {
    city: string,
    province: string
  }
}
export async function signUpService(payload: SignUpPayload): Promise<Omit<Client, "password">>{
  const result = await requestor.post("/auth/client/register", payload);

  return result.data;
}

interface SignInPayload {
  email: string;
  password: string;
}
interface SigninResult {
  accessToken: string;
}
export async function signInService(payload: SignInPayload){
  return (await requestor.post<FRSResponse<SigninResult>>("/auth/client/login",payload)).data?.data;
}

export interface ForgotPasswordPayload {email: string}
export async function forgotPasswordService(payload: ForgotPasswordPayload){
  return (await requestor.post<FRSResponse<never>>("/auth/client/forgot-password", payload)).data;
}

export async function signOut(){
  console.log("seharusnya logout");
}


interface ResendVerificationPayload {
  email: string;
  password: string;
}
export async function resendVerificationService(payload: ResendVerificationPayload){
  const result = await requestor.post("/auth/client/resend-confirm-message", payload);

  return result.data;
}



interface CheckAuthPayload {
  token: string;
}
export async function checkAuthService(payload: CheckAuthPayload): Promise<FRSResponse<{client: Client}>>{
  // const result = await requestor.post("/auth/client/verify-token", {headers: {Authorization: `Bearer ${payload.token}`}});
  const result = await requestor.post("/auth/client/verify-token", {
    accessToken: payload.token
  });
  return result.data;
}

interface VerifyOTPPayload {
  email: string;
  otp: string;
}
export async  function verifyOTPService(payoad:VerifyOTPPayload){
  const result = await requestor.post<FRSResponse<Client>>("/auth/client/verify-otp", payoad)
  return result.data;
}


export async function refreshOTP(email: string): Promise<void>{
  const result = await requestor.post("/auth/client/refresh-otp", {email});
}



export interface SetAdressDetailPayload {
  province: string;
  city: string
}
export async function setAdressDetail(payload: SetAdressDetailPayload): Promise<Client>{
  const result = await requestor.put<FRSResponse<Client>>("/auth/client/address-detail", payload);
  return result.data?.data;
}