import { ReactNode, useState } from "react";
import { AuthContext } from "./context";
import { ClientAuth } from "./types";







interface AuthProviderProps {
  children: ReactNode;
}


export function AuthProvider(props: AuthProviderProps){

  const [user, setUser] = useState<ClientAuth | undefined>(undefined);
  const [storedEmail, setStoredEmail] = useState<string | undefined>(undefined);
  const [isPremium, setIsPremium] = useState<boolean>(false);

  function updateUser(user: ClientAuth) {setUser(user);}

  function clearUser(){setUser(undefined)}

  return (
    <AuthContext.Provider 
      value={{
        user, 
        storedEmail,
        isPremium,
        setIsPremium,
        setClientAuth: updateUser,
        clearClientAuth: clearUser,
        clearStoredEmail: () => setStoredEmail(undefined),
        setStoredEmail
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )

}