


export const Currency = {
  IDR: "IDR",
  USD: "USD"
} as const;

export type Currency = typeof Currency[keyof typeof Currency];

export const CURRENCY = [Currency.IDR, Currency.USD];