import enFlag from "@assets/icon/en-flag.svg";
import idFlag from "@assets/icon/id-flag.svg";

type LanguageList = {
  readonly value: string;
  readonly label: string;
  readonly image: any;
}[]

const languageList : LanguageList = [
  {
    value: "en",
    label: "English",
    image: enFlag
  },
  {
    value: "id",
    label: "Bahasa",
    image: idFlag
  }
];



export {languageList};