
import { Currency } from "@entities/common/currency";
import { requestor } from "./requestor";
import { FRSResponse } from "./response";
import { Donation, DonationSimplified } from "@entities/donation";



type DonationInitField = {
  paymentMethod   : string
  nominal         : number
  proof           : File 
  currency        : Currency
}
type DonationMakePayload = DonationInitField; // how to make type check for data?
export async function sendDonationService(payload: DonationMakePayload): Promise<Donation>{
  const formData = new FormData();
  formData.append("paymentMethod", payload.paymentMethod);
  formData.append("nominal", String(payload.nominal));
  formData.append("proof", payload.proof);
  formData.append("currency", payload.currency);
  
  return (await requestor.post<FRSResponse<Donation>>("/donation/", formData)).data?.data;
}


type AnonymousDonationInitField = {
  paymentMethod   : string
  nominal         : number
  proof           : File 
  email           : string;
  currency        : Currency
}
type AnonymousDonationMakePayload = AnonymousDonationInitField; // how to make type check for data?
export async function sendAnonymousDonationService(
  payload: AnonymousDonationMakePayload
): Promise<Donation>{
  const formData = new FormData();
  formData.append("email", payload.email);
  formData.append("paymentMethod", payload.paymentMethod);
  formData.append("nominal", String(payload.nominal));
  formData.append("donationProof", payload.proof);
  formData.append("currency", payload.currency);
  
  return (await requestor.post<FRSResponse<Donation>>("/donation/anonymous", formData)).data?.data;
}


export async function getMyAcceptedDonation(){
  return (await requestor.get<FRSResponse<DonationSimplified[]>>("/donation/me/accepted")).data?.data;
}