
type AuthLayoutProps = {
  leftSlot?: React.ReactElement;
  navbarSLot? : React.ReactElement;
  rightSlot?: React.ReactElement;
  frameSlot?: React.ReactElement | null;
}

export function AuthLayout(props: AuthLayoutProps){

  // const 


  return (
    <div className="flex flex-col h-screen max-h-screen bg-secondary-dark text-white ">
      {props.frameSlot}
      <div className="bg-secondary-base rounded-t-md h-full flex overflow-hidden">
        <div className="lg:basis-5/12  bg-primary shrink-0 w-full md:grow-0">
          <div className=" h-full overflow-auto flex flex-col  p-10 xl:p-24">
            {props.navbarSLot}
            {props.leftSlot}
          </div>
        </div>
        <div className=" basis-7/12 hidden md:block bg-secondary-base shrink-0 grow-0">
            {props.rightSlot}
        </div>
      </div>
    </div>
  )
}
