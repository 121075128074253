import { cn } from "@utils/tailwind-merge";
import { AnimatePresence, motion } from "framer-motion";
import { ReactElement } from "react";
import { createPortal } from "react-dom";


interface BakcdropProps {
  children?: ReactElement;
  modalAccess?: React.Dispatch<React.SetStateAction<boolean>>
  isOpen : boolean;
  closeByBackdrop?: boolean;
  className?: string
}

const backDropVariant = {
  hide: {
    opacity: 0,
    transition : {
      duration: 0.1
    }
  },
  visible: {
    opacity: 1,
    transition : {
      duration: 0.1
    }
  }
}

const contentVariant = {
  hide: {
    scale: 0.8,
    y: 5,
    transition : {
      duration: 0.1
    }
  },
  visible: {
    scale: 1,
    y: 0,
    transition : {
      duration: 0.1
    }
  }
}



// export function DefaultDialog({closeByBackdrop = true,...props}: BakcdropProps){

//   function handleCloseModal(event: React.MouseEvent<HTMLInputElement>){
//     // warnign prevent default in here will break check input
//     // event.preventDefault();

//     if(event.target === event.currentTarget){
//       if(!props.modalAccess) return
//       props.modalAccess(false);
//     }
//   }

//   return (
//     <>
//     {createPortal(
//       <>
//       <AnimatePresence mode="wait">
//       {props.isOpen && (
//           <motion.div 
//             variants={backDropVariant}
//             initial="hide"
//             animate="visible"
//             exit="hide"
//             onClick={closeByBackdrop ? handleCloseModal : ()=>{}} 
//             className="absolute inset-0 min-h-screen py-10 overflow-auto bg-secondary-dark/50 backdrop-blur-[2px]"
//           >
//             <motion.div 
//               onClick={closeByBackdrop ? handleCloseModal : ()=>{}} 
//               className="flex justify-center items-center h-full relative z-20 overflow-auto"
//               variants={contentVariant}
//             >
//               {props.children && props.children}
//             </motion.div>
//           </motion.div>
//       )}
//       </AnimatePresence>
//       </>,
//     document.getElementById("portal")!
//     )}
//     </>
//   )
// }

export function DefaultDialog({closeByBackdrop = true,...props}: BakcdropProps){

  function handleCloseModal(event: React.MouseEvent<HTMLInputElement>){
    // warnign prevent default in here will break check input
    // event.preventDefault();

    event.stopPropagation()

    if(event.target === event.currentTarget){
      if(!props.modalAccess) return
      props.modalAccess(false);
    }
  }

  return (
    <>
    {createPortal(
      <>

        {/* Backdrop */}
        <div 
          onClick={closeByBackdrop ? handleCloseModal : ()=>{}} 
          className={cn(
            "absolute inset-0 min-h-screen z-20 md:py-10 overflow-auto bg-secondary-dark/50 backdrop-blur-[2px]",
            props.isOpen ? "visible bg-black/20" : "invisible",
            props.className
          )}
        >

          {/* container */}
          <div 
            onClick={closeByBackdrop ? handleCloseModal : ()=>{}} 
            className="flex justify-center items-center h-full relative z-30 overflow-auto w-full"
          >
            {props.children && props.children}
          </div>
        </div>
      </>,
    document.getElementById("portal")!
    )}
    </>
  )
}


