import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { queryClient } from "./query-client";
import { AuthProvider } from "@entities/auth";
import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";
import router from "./router";

import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import "./i18n" ;
import { VirusProvider } from "@entities/virus";







const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <VirusProvider>
          <RouterProvider router={router} />
          <ToastContainer pauseOnFocusLoss={false} />
        </VirusProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
