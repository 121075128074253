import ReactSelect from "react-select";
import { useEffect, useState } from "react";
import i18next from 'i18next'
import { languageList } from "@data/language-list";
import { changeLanguage } from "@services/admin.fixransomware.com/client";







export function SelectLanguageOption(){

  function handleOnChange(e: {image: any, label: string, value: "en" | "id"}){
    i18next.changeLanguage(e.value);

    // change language for this user
    try {
      changeLanguage(e.value);
    } catch (error) {
      console.error("unauthorized user, but it should change the language in client")
    }
  }

  return (
    <ReactSelect
      key={i18next.resolvedLanguage}
      className="z-30"
      // @ts-expect-error shoudl not errir
      onChange={handleOnChange}
      options={languageList}
      defaultValue={i18next.resolvedLanguage === "id" ? languageList[1] : languageList[0]}
      isSearchable={false}
      formatOptionLabel={language => (
        <div className="inline-flex gap-2 text-primary">
          <img src={language.image} alt="language-image" />
          <span>{language.label}</span>
        </div>
      )}
    />
  )
}