import { Client } from "@entities/client";
import { requestor } from "./requestor";
import { FRSResponse } from "./response";





/**
 * change the current user language. (the user value is provided by token in requestor)
 * @param language 
 * @returns 
 */
export async function changeLanguage(language: 'en' | 'id'){
  await requestor.put("client/me/language/", {language});
}


export async function getMyData(){
  const result = await requestor.get<FRSResponse<Client>>("client/me");
  return result.data?.data;
}
