import { cn } from "@utils/tailwind-merge"
import LoaderIcon from "@assets/icon/progress-activity.svg?react";

interface DefaultLoaderProps {
  className?: string
}


export function DefaultLoader(props: DefaultLoaderProps) {
  return <LoaderIcon className={cn("animate-spin w-5 h-5", props.className)} />
}