import { ReactNode } from "react";



export interface ScreenLayoutProps {
  children: ReactNode;
}

export function ScreenLayout(props: ScreenLayoutProps){


  return (
    <main className="h-screen w-screen">
      {props.children}
    </main>
  )
}