import { createContext } from "react";
import { VirusContextProps } from "./types";









export const VirusContext = createContext<VirusContextProps>({
  selectedVirus: undefined,
  clearSelectedVirus: () => {throw new Error("this component is not inside VirusContext")},
  setSelectedVirus: () => {throw new Error("this component is not inside VirusContext")},
})