import { useGetProfile } from "@features/client/get-profile"
import { CreateNewTaskButton, DownloadButton, HomeButton, ProgressButton } from "./components/left-side";
import { ClientRole, ClientType } from "@entities/client";
import { SelectLanguageOption } from "@features/language/change-language";
import { AccountAndAuth } from "./components/right-side";
import { Link, useNavigate } from "react-router-dom";
import { GoToDonation } from "@features/donation/go-to-donation";
import HomeIcon from "@assets/icon/home.svg?react";
import ListIcon from "@assets/icon/list.svg?react";
import PlusIcon from "@assets/icon/plus.svg?react";
import DownloadIcon from "@assets/icon/download.svg?react";
import PersonIcon from "@assets/icon/person.svg?react"
import LogoutIcon from "@assets/icon/logout.svg?react";
import Bar3Icon from "@assets/icon/bar-3.svg?react";
import { cn } from "@utils/tailwind-merge";
import { useTranslation } from "react-i18next";
import { DefaultDropdown } from "@components/dropdown";
import { LogoutWrapper } from "@features/auth/logout";
import { useRef, useState } from "react";


const baseUrl = import.meta.env.VITE_API_HOST;

export function MainNavbar(){

  const [isShow, setIsShow] = useState(false);
  const {t} = useTranslation();
  const {data, isPending, error} = useGetProfile();
  const mobileNavRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  if(isPending) return (
    <div className="flex w-full justify-between py-3 px-9 text-white items-center">
      Loading YGY, tungu sebentar
    </div>
  )

  if(error) return (
    <div className="flex w-full justify-between py-3 px-9 text-danger items-center">
      Error ({error.message})
    </div>
  )

  const isUserPremium = data?.type === ClientType.PREMIUM;

  return (
    <>
      <div className="hidden md:flex md:flex-row flex-col gap-3 w-full justify-between py-3 px-2 md:px-9 text-white items-center">
        <div className="flex md:flex-row flex-col gap-3 w-full">
          <Link
            title="Pergi Ke Home" 
            to={"/"} 
            className={cn(
              "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark border",
              "border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
            )}
          >
            <HomeIcon className="" width={20} height={20} /> 
            <p className="block md:hidden 2xl:block">
              Home
            </p>
          </Link>
          <Link 
            title="Pergi Ke Progress" 
            to={"/progress"} 
            className={cn(
              "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark ",
              "border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800")
            }
          >
            <ListIcon className="" width={20} height={20} /> 
            <p className="block md:hidden 2xl:block">
              Progress
            </p>
          </Link>
          <Link
            to="/reanalisys"
            title="Buat Task Baru" 
            className={cn(
              "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark border",
              " border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
            )}>
            <PlusIcon className="" width={20} height={20} /> 
            <p className="block md:hidden 2xl:block">
              {/* New Task */}
              {t("widgets.main_navbar.reanalisis")}
            </p>
          </Link>
          <a 
              title="Download Aplikasi windows" 
              className={cn(
                "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark",
                " border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
              )} 
              href={baseUrl + "/download-client-app"} 
              target="_blank"
            >
              <DownloadIcon className="" width={20} height={20} />
              <p className="block md:hidden 2xl:block">
                Use Window App
              </p>
          </a>
        </div>
        <div className="flex  w-full gap-2 md:gap-10 justify-end">
          {isUserPremium ? "" : <GoToDonation />}
          <SelectLanguageOption  />


          <DefaultDropdown
            referenceSlot={
              <PersonIcon className="text-gray-200 group-hover:text-gray-300 group-active:text-gray-400" width={30} height={30} />
            }
          >
            <ul className="w-44 text-gray-400">
              <li>
                <Link to={"/profile"} className="w-full px-2 py-1 gap-3 inline-flex items-center hover:bg-secondary-base hover:text-white rounded">
                  <PersonIcon width={20} height={20} className="text-warning" />
                  Account
                </Link>
              </li>
              <li>
                <LogoutWrapper onLogout={() => navigate("/login")}  >
                  <div className="w-full px-2 py-1 gap-3 inline-flex items-center hover:bg-secondary-base hover:text-white rounded">
                    <LogoutIcon width={20} height={20} className="text-warning" />
                    Log out
                  </div>
                </LogoutWrapper>
              </li>
            </ul>
          </DefaultDropdown>

        </div>
      </div>

      {/* mobile nav */}
      <div className="md:hidden bg-secondary-dark">
        <div className="flex w-full md:hidden justify-between py-3 px-2 text-white items-center">
          <img src={"/fixransomware-logo-white.png"} alt="logo" className="w-1/3 px-2" />
          <Bar3Icon className="h-5" onClick={() => setIsShow(!isShow)} />
        </div>
        <div 
          ref={mobileNavRef}
          style={{
            height: isShow ? mobileNavRef?.current?.scrollHeight : "0px",
          }}
          className={cn("px-1 transition-all", isShow ? "overflow-auto" : "overflow-hidden")}
        >
          <div className="flex md:flex-row flex-col gap-1.5 w-full">
            <Link
              title="Pergi Ke Home" 
              to={"/"} 
              className={cn(
                "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark border",
                "border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
              )}
            >
              <HomeIcon className="" width={20} height={20} /> 
              <p className="block md:hidden 2xl:block">
                Home
              </p>
            </Link>
            <Link 
              title="Pergi Ke Progress" 
              to={"/progress"} 
              className={cn(
                "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark ",
                "border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800")
              }
            >
              <ListIcon className="" width={20} height={20} /> 
              <p className="block md:hidden 2xl:block">
                Progress
              </p>
            </Link>
            <Link
              to="/reanalisys"
              title="Buat Task Baru" 
              className={cn(
                "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark border",
                " border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
              )}>
              <PlusIcon className="" width={20} height={20} /> 
              <p className="block md:hidden 2xl:block">
                {/* New Task */}
                {t("widgets.main_navbar.reanalisis")}
              </p>
            </Link>
            <a 
                title="Download Aplikasi windows" 
                className={cn(
                  "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-md bg-secondary-dark",
                  " border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
                )} 
                href={baseUrl + "/download-client-app"} 
                target="_blank"
              >
                <DownloadIcon className="" width={20} height={20} />
                <p className="block md:hidden 2xl:block">
                  Use Window App
                </p>
            </a>
            {isUserPremium ? "" : <GoToDonation className="rounded" />}
            <SelectLanguageOption  />
            <LogoutWrapper onLogout={() => navigate("/login")}  >
              <div
                className={cn(
                  "inline-flex items-center w-full gap-2 px-5 py-1.5 text-danger rounded-md bg-secondary-dark",
                  " border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
                )} 
              >
                <LogoutIcon width={20} height={20} className="" />
                Log out
              </div>
            </LogoutWrapper>
          </div>
        </div>
      </div>
    </>
  )
}
