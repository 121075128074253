import Lottie from "lottie-react";
import hourglass from "@assets/animation/loading-hour-glass.json";






export function Authorizing(){



  return (
    <section className="h-full w-full bg-secondary-base text-white flex items-center justify-center">
      <div className="min-w-[200px] max-w-2xl">
        <Lottie style={{ height: 120 }} animationData={hourglass} loop/>
        <p className="text-xl font-semibold text-center">Authorizing this session</p>
        <p className="text-center">please wait....</p>
      </div>
    </section>
  )
}