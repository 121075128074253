import { AuthContext } from "@entities/auth/context";
import { useContext, useLayoutEffect, useState } from "react";
import {jwtDecode} from "jwt-decode";
import { getLocalToken } from "@services/local-storage";
import { checkAuthService, clearReuestToken, setRequestToken } from "@services/admin.fixransomware.com";
import { Client } from "@entities/client";
import i18next from 'i18next'
import { getMyData } from "@services/admin.fixransomware.com/client";
import { toast } from "react-toastify";


export const AuthState = {
  UNAUTHORIZE: 0,
  LOADING: 1,
  LOGGED_IN: 2,
} as const;

export type AuthState = typeof AuthState[keyof typeof AuthState];


export function useInitializeSession() {
  const [status, setStatus] = useState<AuthState>(AuthState.LOADING);
  const authContext = useContext(AuthContext);

  async function setupClient(){
    try {
      const token = getLocalToken();

      if(typeof token !== "string"){
        setStatus(AuthState.UNAUTHORIZE);
        return;
      }

      if(!token) {
        setStatus(AuthState.UNAUTHORIZE);
        return;
      }

      const serverCheckResult = await checkAuthService({token});

      if(!serverCheckResult) {
        setStatus(AuthState.UNAUTHORIZE);
        return;
      }

      const decoded = jwtDecode<{user: Client}>(token);

      // set token for every request
      setRequestToken(token);

      const myProfile = await getMyData();
      if(!myProfile) {
        clearReuestToken();
        setStatus(AuthState.UNAUTHORIZE);
        toast.error("data token tidak sesuai dengan database");
        return;
      }

      // set client in context so every prop can access it
      authContext.setClientAuth(myProfile);

      const lang = myProfile?.extra?.language;
      if(lang) i18next.changeLanguage(lang);

      setStatus(AuthState.LOGGED_IN);
    } catch (error) {
      console.error(error)
      setStatus(AuthState.UNAUTHORIZE);
    }
  }

  useLayoutEffect(() => {setupClient()}, []);

  return {authStatus: status}

}