
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import * as idLang from "@locales/id.json";
import * as enLang from "@locales/en.json";
import { HttpBackendOptions } from 'i18next-http-backend';

async function loadResources(locale:string) {
  // try{
  //   const result = await getTranslationContent(locale);
  //   return result;
  // }catch(error){
  //   console.error(error);
  //   return loadNewTranslation(locale as "en" | "id");
  // }
  return loadNewTranslation(locale as "en" | "id");
  
}

function loadNewTranslation(locale: "en" | "id"){
  if(locale === "id") return idLang;
  return enLang;
}

const backendOptions: HttpBackendOptions = {
  loadPath: '{{lng}}|{{ns}}', 
  request: (options:any, url:any, payload:any, callback:any) => {
    try {
      const [lng] = url.split('|');
      loadResources(lng).then((response) => {
        callback(null, {data: response,status: 200, });
      });
    } catch (e) {
      console.error(e);
      callback(null, {status: 500,});
    }
  },
};


function i18nWrapper(){
  return i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // use api call
    .use(backend)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      backend: backendOptions,
      supportedLngs: ['id', 'en'],
      fallbackLng: 'id',
      lng: "id",
      debug: true,
      detection : {
        order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie']
      },
      react : {useSuspense : false},
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      // resources : {
      //   en : {translation : loadNewTranslation("en")},
      //   id : {translation : loadNewTranslation("id")}
      // }
    });
}


export default i18nWrapper();