import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


interface GoToLoginEmailProps {
  className? : string;
}

export function GoToLogin(props: GoToLoginEmailProps){
  const { t } = useTranslation();

  return (
    <p className={clsx(props.className)} >
      {t("features.auth.login_by_email.navigation.notice")}
      <Link to={"/login"} className="font-semibold ml-1 hover:underline" >
      {t("features.auth.login_by_email.navigation.link_name")}
      </Link>
    </p>
  )
}