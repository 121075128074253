import { Placement } from "@popperjs/core";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { FocusEvent, MouseEvent, ReactElement, useState } from "react";
import { usePopper } from "react-popper";


interface PopoverProps {
  referenceSlot : ReactElement;
  children: ReactElement;
  className?: string;
  placement?: Placement
}


const contentVariant = {
  hide: {
    opacity: 0,
    scale: 0.8,
    // x: 10,
    // y: -10,
    transition : {
      delay: 0.05,
      duration: 0.05
    }
  },
  visible: {
    opacity: 1,
    scale: 1,
    // x: 0,
    // y: 0,
    transition : {
      duration: 0.05
    }
  }
}

// TODO: do it later
export function Popover(props: PopoverProps){
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleHoverMenu = (e: MouseEvent) => {
    e.preventDefault();
    // e.stopPropagation()
    setOpen(!isOpen);
  };

  const handleClose = (e: MouseEvent) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleOpen = (e: MouseEvent) => {
    e.preventDefault();
    setOpen(true);
  };

  // function closeDropdown(event: MouseEvent){
  //   if(!event.currentTarget) return;
  //   if (!event?.currentTarget.contains(event.relatedTarget)) {
  //     setState('hidden');  
  //   }
  // }

  // # WARNING :: popper setup #
  // popper intentionaly access dom directly to make sure the element is up to date (yet, i'am not sure what this mean)
  // see : https://popper.js.org/react-popper/v2/
  // example how it fail : https://react.dev/learn/manipulating-the-dom-with-refs#best-practices-for-dom-manipulation-with-refs
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement : "top",
    strategy: 'absolute',
    modifiers: [
      {
        name: props.placement ?? 'offset',
        options: {
          offset: [0, 7],
        },
      },
    ]
  });

  /**
   * 
   * @see https://stackoverflow.com/questions/12092261/prevent-firing-the-blur-event-if-any-one-of-its-children-receives-focus
   */
  function handleOnBlur(event: FocusEvent){
    if (!event.currentTarget.contains(event.relatedTarget as Node)){
      setOpen(false)
    }

  }

  return(
  <div 
    // className="w-20" 
    className="relative"
    onBlur={handleOnBlur}
    onMouseLeave={handleClose}
    onMouseEnter={handleOpen}
  >
    <div 
      ref={setReferenceElement}
      // onClick={toggleHoverMenu}
      // 
      // onMouseLeave={toggleHoverMenu}
    >
      {props.referenceSlot}
    </div>
      {/* {props.referenceSlot} */}
      <AnimatePresence>
        {/* {isOpen && ( */}
          <div 
            
            ref={setPopperElement} 
            style={{
              ...styles.popper,
              visibility: isOpen ? "visible" : "hidden"
            }} 
            {...attributes.popper}
          >
            <div style={styles.arrow} />
            <div
              className={clsx(
                "p-1 bg-secondary-light shadow  rounded z-20 transition-all ",
                isOpen ? "scale-100 opacity-100" : "scale-75 opacity-0",
                props.className
              )}
              // variants={contentVariant}
              // initial="hide"
              // animate="visible"
              // exit="hide"
            >
              {props.children}
            </div>
          </div>
        {/* )} */}
      </AnimatePresence>
  </div>
  )
}