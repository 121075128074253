import { ComponentPropsWithoutRef, ReactNode } from "react";
import { DefaultDialog } from "../default/DefaultDialog";
import XIcon from "@assets/icon/x.svg?react"
import { cn } from "@utils/tailwind-merge";
import { createPortal } from "react-dom";

interface DialogWithHeaderProps extends Omit<ComponentPropsWithoutRef<"div">, "title">{
  title?: string | ReactNode;
  // children?: ReactElement;
  modalAccess: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen : boolean;
  contentClassName?: string;
  backDropClassName?: string
}


// export function DialogWithHeader ({title, modalAccess, isOpen, children, className,  ...props}: DialogWithHeaderProps){
//   return (
//     <DefaultDialog isOpen={isOpen} modalAccess={modalAccess}>
//       <div 
//         className={cn(
//           "bg-secondary-base text-white basis-1/2 rounded-md flex flex-col max-h-full",
//           className,
//         )} 
//         {...props}
//       >
//         <div className="flex justify-between p-2">
//           <h4 className="text-gray-400 w-full">{title}</h4>
//           <button 
//             onClick={() => modalAccess(false)}
//             className="hover:text-gray-500 active:text-gray-600"
//           >
//             <XIcon width={25} height={25} />
//           </button>
//         </div>
//         <section className="p-2 overflow-auto">
//           {children}
//         </section>
//       </div>
//     </DefaultDialog>
//   )
// }

export function DialogWithHeader ({title, modalAccess, contentClassName, isOpen, children, className,  ...props}: DialogWithHeaderProps){


  function handleCloseModal(e: React.MouseEvent){
    e.preventDefault();
    e.stopPropagation();
    modalAccess(false)
  }

  return (
    <DefaultDialog className={props.backDropClassName} isOpen={isOpen} modalAccess={modalAccess}>
      <div 
        className={cn(
          "bg-secondary-base text-white rounded-md flex flex-col max-h-full transition-all duration-150 ",
          "md:basis-1/2 w-full h-full",
          isOpen ? "scale-100 opacity-100" : "scale-95 opacity-0",

          className,
        )} 
        {...props}
      >
        <div className="flex justify-between p-2 w-full">
          <h4 className="text-gray-400 w-full">{title}</h4>
             <button 
                onClick={handleCloseModal}
                className="hover:text-gray-500 active:text-gray-600"
              >
              <XIcon width={25} height={25} />
              </button>
        </div>
        <section className={cn("p-2 overflow-auto", contentClassName)}>
          {children} 
        </section>
      </div>
    </DefaultDialog>
  )
}