import { createBrowserRouter, RouteObject } from "react-router-dom";
import { lazy } from "react";

import AuthLayoutRoute from "./app-layout/auth-layout";
import MainLayoutRoute from "./app-layout/main-layout";
import AnonymousDonationPage from "@pages/anonymous-donation/index.page";

const HomePage = lazy(() => import("@pages/home.page"));
const LoginPage = lazy(() => import("@pages/login.page"));
const RegisterPage = lazy(() => import("@pages/register.page"));
const ForgotPasswordPage = lazy(() => import("@pages/forgot-password.page"));
const OTPVerificationPage = lazy(() => import("@pages/otp-verification.page"));
const ReanalisysPage = lazy(() => import("@pages/reanalisys.page"));
const NotFoundPage = lazy(() => import("@pages/not-found.page"));
const DonationPage = lazy(() => import("@pages/donation/index"));
const ProgressPage = lazy(() => import("@pages/progress"));
const ProgressDetailPage = lazy(() => import("@pages/progress/[taskId].page"));






const routes: RouteObject[] = [
  {path: "hello", element: <h1>Hello</h1>},

  // # auth group
  {
    element: <AuthLayoutRoute/>,
    children:[
      {path: "login", element: <LoginPage />},
      {path: "register", element: <RegisterPage />},
      {path: "change-password", element: <ForgotPasswordPage/>},
      {path: "otp-verification", element: <OTPVerificationPage />},
      {path: "donate-now", element: <AnonymousDonationPage />},
      
    ]
  },

  // main layout group
  {
    element: <MainLayoutRoute />,
    children: [
      {path: "", element: <HomePage />},
      {path: "reanalisys", element: <ReanalisysPage/>},
      {path: "donation", element: <DonationPage />},
      {path: "progress", element: <ProgressPage />},
      {path: "progress/:taskId", element: <ProgressDetailPage />},
    ]
  },

  {path: "*", element: <NotFoundPage />},
]

export default createBrowserRouter(routes);