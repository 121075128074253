import { getMyData } from "@services/admin.fixransomware.com/client";
import { useQuery } from "@tanstack/react-query";
import { ClientKey, ClientMeKey } from "../client-key";
import { Client } from "@entities/client";









export function useGetProfile(){

  return useQuery<Client>({
    queryKey: [ClientKey, ClientMeKey],
    staleTime: 1000 * 60,
    queryFn: () => getMyData()
  })

}