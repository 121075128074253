




export function getLocalToken(){
  const token = localStorage.getItem("jwtToken");
  return token;
}
export function removeLocalToken(){
  localStorage.removeItem("jwtToken");
}
export function setLocalToken(token: string){
  if(!token) throw new Error("token is required");
  localStorage.setItem("jwtToken", token);
}