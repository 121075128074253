import { sendAnonymousDonationService } from "@services/admin.fixransomware.com";
import { SendDonationPayload } from "./send-anonymous-donation.type";
import { extractAxiosRequestError } from "@utils/axios-error";





export interface useSendAnonymousDonationProps {
  onSuccess?: () => void
}


export function useSendAnonymousDonation(props?: useSendAnonymousDonationProps){


  async function send(payload: SendDonationPayload){
    try {
      const result = await sendAnonymousDonationService(payload);
      if(props?.onSuccess) props.onSuccess();
    } catch (error) {
      throw extractAxiosRequestError(error);
    }
  }

  return {send}

}