import { cn } from "@utils/tailwind-merge";
import { Link } from "react-router-dom";
import medalImage from "@assets/icon/medal.png";

interface ToDonationNavigationProps {
  className ?: string;
  shape?: "smooth" | "round" | "square";
}

export function GoToDonation(props: ToDonationNavigationProps){
  return (
    <Link
      to={"/donation"} 
      className={cn(
        "bg-warning hover:bg-warning/90 active:bg-warning/80 inline-flex",
        "items-center gap-3 px-5 py-1.5 text-secondary-dark",
        {
          "rounded-full": props.shape === "round",
          "rounded-lg": props.shape === "smooth" || !props.shape,
          "rounded-none": props.shape === "square",
        },
        props.className
      )}
    >
      <img src={medalImage as string} alt="" />
      DONATE AND BECOME PREMIUM
    </Link>
  )
}