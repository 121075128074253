import { cn } from "@utils/tailwind-merge"





export interface MountingProps{
  className?: string
}


export function MountingMain(props: MountingProps) {


  return (
    <section 
      className={cn(
        "h-full w-full bg-secondary-base flex items-center justify-center animate-shimmer", 
        // "bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%]",
        "bg-[linear-gradient(90deg,#222222,45%,#3a3939,55%,#222222)] bg-[length:200%_100%]",
        props.className
      )}
    >
    </section>
  )
}
