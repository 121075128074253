import { SelectLanguageOption } from "@features/language/change-language";




export function LogoAndLanguage(){
  
  
  return (
    <div className="flex justify-between items-center mb-10">
      <img src={"/fixransomware-logo-white.png"} width={200} />
      <SelectLanguageOption />
    </div>
  )
}